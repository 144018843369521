import React, { useState } from "react";
import "./style.css";

const FormPage = () => {
  const quizOptions = [
    { label: "None", value: null },
    { label: "Yes in chat", value: "chat" },
    { label: "Yes in workspace", value: "workspace" },
  ];
  const [quiz, setQuiz] = useState(null);
  const [chatMessage, setChatMessage] = useState("");
  const [workspaceImage, setWorkspaceImage] = useState(null);
  const [workspaceMessage, setWorkspaceMessage] = useState("");
  const [navigateNext, setNavigateNext] = useState(false);
  const [answerChoices, setAnswerChoices] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission logic here
    console.log({
      quiz,
      chatMessage,
      workspaceMessage,
      navigateNext,
      answerChoices,
    });
  };

  return (
    <div className="form-page">
      <form onSubmit={handleSubmit}>
        <div className="form-section">
          <h2>Quiz</h2>
          <div className="quiz-options">
            {quizOptions.map((option) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => setQuiz(option.value)}
              >
                <div
                  style={{
                    backgroundColor:
                      quiz === option.value ? "#00D26A" : "#D9D9D9",
                    borderRadius: "50%",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
                <h5 style={{ cursor: "pointer" }}>{option.label}</h5>
              </div>
            ))}
          </div>
        </div>

        <h2>Chat</h2>

        <div className="form-section">
          <label>Chat message</label>
          <textarea
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
          />
        </div>

        {quiz === "chat" && (
          <div className="form-section">
            <label>Answer choices</label>
            <div className="answer-choices">
              {answerChoices.map((choice, index) => (
                <div key={index}>
                  <textarea
                    value={choice}
                    onChange={(e) => {
                      const newChoices = [...answerChoices];
                      newChoices[index] = e.target.value;
                      setAnswerChoices(newChoices);
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const newChoices = [...answerChoices];
                      newChoices.splice(index, 1);
                      setAnswerChoices(newChoices);
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => setAnswerChoices([...answerChoices, ""])}
              >
                Add answer choice
              </button>
            </div>
          </div>
        )}

        <h2>Workspace</h2>

        {/* add workspace image */}
        <div className="form-section">
          <label>Workspace image</label>

          {/* upload image */}
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={(e) => setWorkspaceImage(e.target.files[0])}
            style={{
              display: "none",
            }}
            id="workspace-image"
          />
          <div>
            {workspaceImage ? (
              <img
                src={URL.createObjectURL(workspaceImage)}
                alt="workspace"
                style={{
                  width: "100%",
                  // height: "300px",
                }}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#D9D9D9",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                No media
              </div>
            )}
            <label
              for="workspace-image"
              style={{
                cursor: "pointer",
                marginTop: "10px",
                marginBottom: "10px",
                display: "block",
                textAlign: "center",
                backgroundColor: "#D9D9D9",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              + add media
            </label>
          </div>
          {/* remove media */}
          {workspaceImage && (
            <button type="button" onClick={() => setWorkspaceImage(null)}>
              Remove media
            </button>
          )}
        </div>

        <div className="form-section">
          <label>Workspace message</label>
          <textarea
            value={workspaceMessage}
            onChange={(e) => setWorkspaceMessage(e.target.value)}
          />
        </div>

        {quiz === "workspace" && (
          <div className="form-section">
            <label>Answer choices</label>
            <div className="answer-choices">
              {answerChoices.map((choice, index) => (
                <div key={index}>
                  <textarea
                    value={choice}
                    onChange={(e) => {
                      const newChoices = [...answerChoices];
                      newChoices[index] = e.target.value;
                      setAnswerChoices(newChoices);
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const newChoices = [...answerChoices];
                      newChoices.splice(index, 1);
                      setAnswerChoices(newChoices);
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => setAnswerChoices([...answerChoices, ""])}
              >
                Add answer choice
              </button>
            </div>
          </div>
        )}

        <div className="form-section">
          <label>
            Does this page require a button to proceed to the next page?
          </label>
          {/* <div>
            <input
              type="radio"
              name="navigateNext"
              value="no"
              checked={!navigateNext}
              onChange={() => setNavigateNext(false)}
            />{" "}
            No
            <input
              type="radio"
              name="navigateNext"
              value="yes"
              checked={navigateNext}
              onChange={() => setNavigateNext(true)}
            />{" "}
            Yes
          </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              marginRight: "20px",
            }}
            onClick={() => setNavigateNext(false)}
          >
            <div
              style={{
                backgroundColor: !navigateNext ? "#00D26A" : "#D9D9D9",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
              }}
            />
            <h5 style={{ cursor: "pointer" }}>No</h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              marginRight: "20px",
            }}
            onClick={() => setNavigateNext(true)}
          >
            <div
              style={{
                backgroundColor: navigateNext ? "#00D26A" : "#D9D9D9",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
              }}
            />
            <h5 style={{ cursor: "pointer" }}>Yes</h5>
          </div>
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default FormPage;
