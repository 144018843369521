import React from "react";
import FormPage from "../../components/form";

const HomePage = () => {
  return (
    <>
      <FormPage />
    </>
  );
};

export default HomePage;
